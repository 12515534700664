import { AfterViewChecked, Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { sessionContext, TenantInfo, UserInfo } from '../authentication/session-details';
import {
  GoConfigService,
  GoModalService,
  GoOffCanvasService,
  GoSideNavService,
  NavAppDrawer,
  NavGroup,
  NavItem,
} from '@tangoe/goponents';
import { AuthenticationService } from '../authentication/authentication.service';
import { ExternalResourceEventHandler } from '../external-resource/event-handler';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { TenantSwitcherComponent } from '../shared/tenant-switcher/tenant-switcher.component';
import { MenuProvider } from './app-shell.menus';
import { forkJoin } from 'rxjs';
import { LogService } from '../logger/log.service';
import { HelpService } from './help.service';
import { IaasOptimizationService } from '../iaas-optimization/iaas-optimization.service';
import { AboutModalComponent } from '../about-modal/about-modal.component';
import { Environment } from '../shared/environment';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-app-shell',
  templateUrl: './app-shell.component.html',
  styleUrls: ['./app-shell.component.scss'],
})
export class AppShellComponent implements OnInit, AfterViewChecked {
  title = 'Cloud Expense Management';
  date = new Date().getFullYear();
  logo = '../../../assets/img/logo-main.png';
  altLogo = '../../../assets/img/logo.png';
  sideNavIsOpen = true;
  MAX_LOGOUT_RETRIES: number = 3;
  LOGOUT_COUNT: number = 0;
  appLoading: boolean = true;
  hasMultipleTenantAccess: boolean = false;
  displayLoader = false;

  userDetails: any = sessionContext.userInfo;
  tenantDetails: any = sessionContext.tenantInfo;

  saasDefaultTargetUrl: string;

  walkmeScript: any;
  buildRecord: any;

  menuItems: Array<NavGroup | NavItem> = [];

  isSaasUser = false;
  logoutIntervalId: any;
  navAppDrawer: NavAppDrawer = {
    currentAppIcon: 'cloud',
    currentAppDisplayName: 'Cloud',
  };
  appDrawerHeader: string = 'Launch';
  textColor: any;

  constructor(
    private authenticationService: AuthenticationService,
    private helpService: HelpService,
    private logService: LogService,
    private goConfigService: GoConfigService,
    public sideNavService: GoSideNavService,
    protected router: Router,
    private goOffCanvasService: GoOffCanvasService,
    private menuProvider: MenuProvider,
    private externalResourceEventHandler: ExternalResourceEventHandler,
    private iaasOptimizationService: IaasOptimizationService,
    private goModalService: GoModalService,
    private renderer2: Renderer2,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.sideNavIsOpen = this.sideNavService.navOpen;
  }

  ngOnInit() {
    this.menuItems = this.menuProvider.secureMenuItems();

    this.navAppDrawer.appDrawerConfig = this.menuProvider.buildApplicationDrawer();

    this.hasMultipleTenantAccess = sessionContext.tenantList.length > 0;

    this.appLoading = false;

    this.externalResourceEventHandler.registerEventHandler();

    this.isSaasUser = this.checkSaasRole();

    this.loadWalkmeScript();
    this.loadBuildInfo();
    if (typeof (window as any).pendo !== 'undefined') {
      (window as any).pendo.initialize({
        visitor: {
          id: sessionContext.userInfo.userIdentifier,
          role: 'user',
          product: 'Cloud',
          version: sessionContext.appInfo?.buildVersion,
        },
        account: {
          id: sessionContext.tenantInfo.shortName,
          name: sessionContext.tenantInfo.tenantName,
        },
      });
    }
    this.appDrawerHeader = sessionContext.tenantInfo.tenantName;
  }

  ngAfterViewChecked(): void {
    const CONFIGLOC = this.goConfigService.getConfig();
    this.textColor = CONFIGLOC.brandColor;
  }

  logout() {
    this.logService.debug(`App-Shell:: User trying to logout from the Cloud application`);
    this.authenticationService.logoutEvent = true;
    this.clearIaasCache();

    const isSaasRole = this.authenticationService.getSaasResourceList();
    if (isSaasRole.length) {
      this.logoutIntervalId = setInterval(() => {
        this.LOGOUT_COUNT = this.LOGOUT_COUNT + 1;
        if (this.authenticationService.saasPartnerLogout || this.LOGOUT_COUNT >= this.MAX_LOGOUT_RETRIES) {
          if (this.LOGOUT_COUNT >= this.MAX_LOGOUT_RETRIES) {
            this.logService.info(`App-Shell:: SaaS Partner logout reached max tries ,user will be logged out from app`);
          }
          clearInterval(this.logoutIntervalId);
          const sisenseRole = this.authenticationService.getReportingRole();
          if (sisenseRole) {
            this.sisenseLogout();
          }
          this.invalidateSession();
        }
      }, 3000);
      this.logoutFromSaas();
    } else {
      const sisenseRole = this.authenticationService.getReportingRole();
      if (sisenseRole) {
        this.sisenseLogout();
      }
      this.invalidateSession();
    }
  }

  checkSaasRole() {
    const isSaasRole = this.authenticationService.getSaasResourceList();
    if (isSaasRole.length) {
      return true;
    }
  }

  checkDefaultSaasIframe() {
    const isSaasPath = window.location.pathname.includes('saas-external-resource');
    if (!isSaasPath && this.isSaasUser) {
      this.saasDefaultTargetUrl = `${environment.saasIframeUrl}?partner=tangoe&iframe=true&connection=${environment.saasSamlConnectionName}`;
      return true;
    } else {
      return false;
    }
  }

  invalidateSession() {
    forkJoin([this.authenticationService.logoutUser(), this.authenticationService.invalidateSession()]).subscribe(
      data => {
        this.logService.info(`App-Shell:: User successfully logged out from the Cloud application`);
        this.clearUserSession();
      },
      error => {
        this.logService.error(`App-Shell:: Error while user trying to logout from the Cloud application`, error);
        this.clearUserSession();
      }
    );
  }

  clearIaasCache() {
    const cloudadminAccessToken = localStorage.getItem('cloudadminAccessToken');
    if (cloudadminAccessToken) {
      this.authenticationService.logoutIaasToken().subscribe(
        () => {
          // Remove IAAS token from localStorage
          this.iaasOptimizationService.removeCloudadminAccessToken();
        },
        () => {
          this.iaasOptimizationService.removeCloudadminAccessToken();
        }
      );
    }
  }

  sisenseLogout() {
    const sisenseLogoutframe = document.createElement('iframe');
    sisenseLogoutframe.id = 'sisenseLogoutframe';
    sisenseLogoutframe.src = Environment.getSisenseApiV1Url() + 'authentication/logout';
    sisenseLogoutframe.setAttribute('hidden', 'hidden');
    if (sessionStorage.getItem('tenantSwitched')) {
      sessionStorage.removeItem('tenantSwitched');
    }
    document.body.appendChild(sisenseLogoutframe);
  }

  logoutFromSaas() {
    const saasiframe = document.getElementById('saasiframe') as HTMLIFrameElement;
    const zluriURL = environment.saasIframeUrl;
    if (saasiframe) {
      saasiframe.src = `${environment.saasIframeUrl}/logout?partner=tangoe&iframe=true&connection=${environment.saasSamlConnectionName}`;
      saasiframe.contentWindow.postMessage({ type: 'navigate', to: '/logout' }, zluriURL);
    } else {
      this.displayLoader = true;
      const saasDefaultIframe = document.createElement('iframe');
      saasDefaultIframe.id = 'saasDefaultIframe';
      saasDefaultIframe.src = `${environment.saasIframeUrl}/logout?partner=tangoe&iframe=true&connection=${environment.saasSamlConnectionName}`;
      saasDefaultIframe.setAttribute('hidden', 'hidden');
      document.body.appendChild(saasDefaultIframe);
      if (saasDefaultIframe) {
        saasDefaultIframe.contentWindow.postMessage({ type: 'navigate', to: '/logout' }, zluriURL);
      }
    }
  }

  clearUserSession() {
    sessionContext.loginSuccess = false;
    sessionContext.userInfo = new UserInfo();
    sessionContext.tenantInfo = new TenantInfo();
    sessionContext.tenantList = [];

    environment.x_tngo_tenant = '';
    environment.x_tngo_user = '';
    this.displayLoader = false;

    // Call the flush method to ensure old logs are flushed before reloading the page.
    this.logService.flushAll();

    this.iaasOptimizationService.removeCloudadminAccessToken();

    window.location.replace(environment.ssoLogoutUrl);
  }

  showUserPreference() {
    this.router.navigate(['/user-preferences']);
  }

  performTenantSwitch(tenant: TenantInfo): void {
    const currentTenant = sessionContext.tenantInfo.shortName;
    const switchToTenant = tenant.shortName;
    let selectedUserRecord;

    this.logService.debug(`App-Shell:: Trying to switch tenant from ${currentTenant} to ${switchToTenant}`);

    this.appLoading = true;
    sessionContext.loginSuccess = false;
    sessionContext.permissions = [];

    this.menuItems = [];

    this.goOffCanvasService.closeOffCanvas();
    this.authenticationService.switchTenant(tenant).subscribe(element => {
      sessionContext.loginSuccess = true;
      environment.x_tngo_tenant = sessionContext.tenantInfo.shortName;
      environment.x_tngo_user = sessionContext.userInfo.userIdentifier;

      this.logService.info(`App-Shell:: Successfully switched tenant from ${currentTenant} to ${switchToTenant}`);

      sessionStorage.setItem('tenant_switch', 'true');
      sessionStorage.setItem('tenantSwitched', 'true');
      // Call the flush method to ensure old logs are flushed before reloading the page.
      this.logService.flushAll();
      if (element) {
        selectedUserRecord = element[1].user_details;
      }

      this.iaasOptimizationService.updateTenantInCloudAdmin(tenant, selectedUserRecord).subscribe(
        data => {
          this.logService.info(
            `App-Shell:: Successfully updated tenant in cloud admin side from ${currentTenant} to ${switchToTenant}`
          );
          this.loadOnTenantSwitch();
        },
        err => {
          this.logService.info(
            `App-Shell:: Failed to update tenant in cloud admin side from ${currentTenant} to ${switchToTenant}`
          );
          this.loadOnTenantSwitch();
        }
      );
    });
  }
  loadOnTenantSwitch() {
    this.router.navigate(['/']).then(() => {
      window.location.reload();
    });
  }
  openHelp(): void {
    window.open(this.helpService.loggedInUserHelp(), '_blank');
  }

  openTenantSwitcher(): void {
    this.goOffCanvasService.openOffCanvas({
      component: TenantSwitcherComponent,
      bindings: {
        userInfo: sessionContext.userInfo,
        tenantInfo: sessionContext.tenantInfo,
        tenantList: sessionContext.tenantList,
        callback: this.performTenantSwitch.bind(this),
      },
      header: 'Select Tenant',
    });
  }

  appInfo(): void {
    this.goModalService.openModal(AboutModalComponent, {
      buildInfo: this.buildRecord,
      modalTitle: 'About Cloud',
      closeWithBackdrop: true,
      modalSize: 'xl',
    });
  }

  loadBuildInfo() {
    this.authenticationService.buildInfo().subscribe(
      data => {
        this.buildRecord = data.build_info;
        this.loadWalkmeData();
      },
      error => {
        this.loadWalkmeData();
      }
    );
  }

  loadWalkmeScript() {
    this.updateWalkmeScript();
    const element = this.renderer2.createElement('script');
    element.text = this.walkmeScript;
    element.type = 'text/javascript';
    this.renderer2.appendChild(this.document.head, element);
  }

  updateWalkmeScript() {
    this.walkmeScript = (() => {
      const walkme = document.createElement('script');
      walkme.type = 'text/javascript';
      walkme.async = true;
      walkme.src = environment.walkmeUrl;
      const s = document.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(walkme, s);
      (window as any)._walkmeConfig = { smartLoad: true };
    })();
    this.loadWalkmeData();
  }

  loadWalkmeData() {
    const userDetails = this.authenticationService.getUserDetails();
    const isSaasRole = this.authenticationService.getSaasResourceList();
    const isIaasRole = this.authenticationService.getResourceList();
    const tenantName = sessionContext.tenantInfo.tenantName;
    (window as any).appUserFirstName = userDetails.first_name;
    (window as any).appUserEmail = userDetails.email;
    (window as any).appCustomerName = tenantName;
    (window as any).appIaaSOpt = isIaasRole ? 'Yes' : 'No';
    (window as any).appSaaS = isSaasRole.length ? 'Yes' : 'No';
    (window as any).appIaaSSaaS = isSaasRole.length && isIaasRole ? 'Yes' : 'No';
    (window as any).appVersion = sessionContext.appInfo?.buildVersion;
  }
}
